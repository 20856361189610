import { getRetailerIdIssueProposal } from '@common/utils';
import { ErrorTypes } from '../utils/definitions';
const {
  getLogger
} = svs.core.log;
const logger = getLogger('retail:authenticate');
class AuthenticateError extends Error {
  constructor(obj = {}) {
    super(obj);
    const defaults = obj.defaults || {
      type: ErrorTypes.GENERAL,
      title: 'Något gick fel',
      message: 'Ett oväntat fel inträffade.<br/>Vid frågor, kontakta ombudssupport 020-78 83 55.'
    };
    this.lookupErrorCode(obj, defaults);
  }
  lookupErrorCode({
    code,
    retailerId
  } = {}, defaults = {}) {
    switch (code) {
      case 2:
      case 13:
        {
          logger.info('Failed to login retailer due to bad credentials.', {
            code
          });
          const issueProposal = getRetailerIdIssueProposal(retailerId);
          const message = `${issueProposal} Har du glömt ditt lösenord?<br />
        Vänligen kontakta din administratör.`;
          this.set({
            code,
            type: ErrorTypes.WRONG_CREDENTIALS,
            severe: true,
            title: 'Felaktigt ombudsnummer, användarnamn eller lösenord.',
            message
          });
          break;
        }
      case 5:
        logger.info('Failed to login retailer due to too many login attempts.', {
          code
        });
        this.set({
          code,
          type: ErrorTypes.TOO_MANY_LOGIN_ATTEMPTS,
          icon: 'rectify-error',
          severe: false,
          title: 'Kontot är låst',
          message: 'Ditt lösenord är nu ogiltigt p.g.a. många felaktiga inloggningsförsök.<br/>Kontakta ombudssupport 020-78 83 55.'
        });
        break;
      case 6:
        logger.info('Failed to login retailer due to account is locked.', {
          code
        });
        this.set({
          code,
          type: ErrorTypes.ACCOUNT_LOCKED,
          icon: 'rectify-error',
          severe: false,
          title: 'Inloggning misslyckades',
          message: 'Ditt konto är spärrat.<br/>Vid frågor, kontakta ombudssupport 020-78 83 55.'
        });
        break;
      default:
        logger.error('Failed to login retailer due to unknown error.', {
          code
        });
        this.set({
          ...defaults,
          code
        });
    }
  }
  set({
    code,
    icon,
    type,
    title,
    message,
    severe = true
  }) {
    this.code = code;
    this.type = type;
    this.icon = icon;
    this.title = title;
    this.message = message;
    this.severe = severe;
  }
}
export default AuthenticateError;