import Login from './login/login';
import { defineEventTypes, defineErrorTypes, ErrorTypes, Events } from './utils/definitions';
const loginSingleton = new svs.core.Events(new Login());
defineEventTypes({
  AUTHENTICATE_ERROR: 'AUTHENTICATE_ERROR',
  AUTHENTICATE: 'AUTHENTICATE',
  GET_USER_METADATA: 'GET_USER_METADATA',
  GET_USER_METADATA_ERROR: 'GET_USER_METADATA_ERROR',
  LOGIN: 'LOGIN',
  LOGIN_ERROR: 'LOGIN_ERROR',
  PASSWORD_CHANGED: 'PASSWORD_CHANGED',
  PASSWORD_CHANGE_ERROR: 'PASSWORD_CHANGE_ERROR'
});
defineErrorTypes({
  ALREADY_IN_PROGRESS: 'ALREADY_IN_PROGRESS',
  GENERAL: 'GENERAL',
  INVALID_PARAMETERS: 'INVALID_PARAMETERS',
  WRONG_CREDENTIALS: 'WRONG_CREDENTIALS',
  TOO_MANY_LOGIN_ATTEMPTS: 'TOO_MANY_LOGIN_ATTEMPTS',
  ACCOUNT_LOCKED: 'ACCOUNT_LOCKED',
  PASSWORD_UNCHANGED: 'PASSWORD_UNCHANGED',
  PASSWORD_TOO_SHORT: 'PASSWORD_TOO_SHORT'
});
async function login(options) {
  await loginSingleton.login(options);
}
function changePassword(options) {
  loginSingleton.changePassword(options);
}
function on(...args) {
  loginSingleton.listenTo(...args);
}
function off(...args) {
  loginSingleton.stopListeningTo(...args);
}
export default {
  login,
  changePassword,
  on,
  off,
  Events,
  ErrorTypes
};
setGlobal('svs.components.retail.Authenticate.login', {
  login,
  changePassword,
  on,
  off,
  Events
});