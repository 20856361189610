import { sanitizeRetailerId } from '@common/utils';
import AuthenticateError from '../errors/authenticate-error';
import { default as PasswordChangeError } from '../errors/password-change-error';
import { ErrorTypes, Events } from '../utils/definitions';
const {
  getLogger
} = svs.core.log;
const logger = getLogger('retail:authenticate');
class Login {
  async login(options) {
    try {
      await this.authenticate(options);
      this.triggerAsync(Events.LOGIN);
    } catch (error) {
      this.triggerAsync(Events.LOGIN_ERROR);
    }
  }
  async changePassword(options) {
    try {
      this.validatePasswordChange(options);
      this.createActivePasswordChangeProcess(options);
      await this.callChangePasswordAPI();
    } catch (err) {
      this.triggerError(Events.PASSWORD_CHANGE_ERROR, err);
    }
  }
  async authenticate(options) {
    try {
      this.validateAuthentication(options);
      this.createActiveProcess(options);
      await this.callLoginAPI();
    } catch (err) {
      this.triggerError(Events.AUTHENTICATE_ERROR, new AuthenticateError({
        ...err,
        retailerId: options.retailerId
      }));
      throw err;
    }
  }
  async callChangePasswordAPI() {
    const {
      currentPassword: oldPassword,
      newPassword
    } = this.activeProcess;
    try {
      await svs.core.jupiter.callAsync({
        path: '/user/2/password',
        method: 'PUT',
        data: {
          oldPassword,
          newPassword
        }
      });
      this.endProcess();
      this.triggerAsync(Events.PASSWORD_CHANGED);
    } catch (err) {
      logger.error('Could not change password with PUT /user/2/password', err);
      this.triggerError(Events.PASSWORD_CHANGE_ERROR, new PasswordChangeError(err));
    }
  }
  async callLoginAPI() {
    const {
      retailerId,
      username,
      password
    } = this.activeProcess;
    const sanitizedUsername = sanitizeRetailerId(retailerId) + username.trim();
    logger.info(`Will call /retailer/1/sessions/webterminal with userName '${sanitizedUsername}'`);
    const {
      response
    } = await svs.core.jupiter.callAsync({
      path: '/retailer/1/sessions/webterminal',
      method: 'POST',
      data: {
        userName: sanitizedUsername,
        password,
        source: '0'
      }
    });
    this.endProcess();
    return new Promise(resolve => {
      logger.info(`Successfully authenticated user '${username}' at retailer id '${retailerId}' ('${sanitizedUsername}').`);
      this.triggerAsync(Events.AUTHENTICATE, {
        mustChangePassword: response.userNotifications?.REDIRECT
      });
      resolve();
    });
  }
  createActiveProcess({
    retailerId,
    username,
    password
  }) {
    this.activeProcess = {
      retailerId,
      username,
      password
    };
  }
  cretePasswordChangeValidationError(message) {
    return new PasswordChangeError({
      type: ErrorTypes.INVALID_PARAMETERS,
      severe: true,
      message
    });
  }
  validateAuthentication() {
    if (this.activeProcess) {
      throw new AuthenticateError({
        type: ErrorTypes.ALREADY_IN_PROGRESS,
        severe: true,
        message: 'Du har redan en pågående inloggning.'
      });
    }
  }
  validatePasswordChange({
    currentPassword,
    newPassword,
    repeatNewPassword
  } = {}) {
    const {
      cretePasswordChangeValidationError: validationError
    } = this;
    if (this.activeProcess) {
      throw new PasswordChangeError({
        type: ErrorTypes.ALREADY_IN_PROGRESS,
        severe: true,
        message: 'Du har redan en pågående inloggning.'
      });
    }
    const missingFields = [];
    !currentPassword && missingFields.push('nuvarande lösenord');
    !newPassword && missingFields.push('nytt lösenord');
    !repeatNewPassword && missingFields.push('upprepa nytt lösenord');
    if (missingFields.length) {
      throw validationError(`Du måste fylla i ${missingFields.join(', ')}`);
    }
    if (newPassword !== repeatNewPassword) {
      throw validationError('Lösenorden stämmer inte överens');
    }
    if (currentPassword === repeatNewPassword) {
      throw validationError('Lösenordet får inte vara identiskt med ditt nuvarande lösenord');
    }
    if (!/\d/.test(newPassword)) {
      throw validationError('Nya lösenordet måste innehålla minst 1 siffra');
    }
    if (!/\D/.test(newPassword)) {
      throw validationError('Nya lösenordet måste innehålla minst 1 bokstav');
    }
  }
  createActivePasswordChangeProcess({
    currentPassword,
    newPassword
  }) {
    this.activeProcess = {
      currentPassword,
      newPassword
    };
  }
  endProcess() {
    delete this.activeProcess;
  }
  triggerError(...args) {
    this.endProcess();
    this.triggerAsync(...args);
  }
  triggerAsync(event, data) {
    return setTimeout(() => this.trigger(event, data), 30);
  }
  listenTo(...args) {
    if (args.length < 3 && typeof args[0] === 'object') {
      const obj = args[0];
      const context = args[1];
      Object.keys(obj).forEach(key => {
        this.on(key, obj[key], context);
      });
    } else {
      this.on(...args);
    }
  }
  stopListeningTo(...args) {
    if (args.length < 3 && typeof args[0] === 'object') {
      const obj = args[0];
      Object.keys(obj).forEach(key => {
        this.off(key, obj[key]);
      });
    } else {
      this.off(...args);
    }
  }
}
export default Login;