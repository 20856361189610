import { ErrorTypes } from '../utils/definitions';
class PasswordChangeError extends Error {
  constructor(err = {}) {
    super(err);
    this.lookupErrorCode(err);
  }
  lookupErrorCode(err) {
    switch (Number(`${err.code}`.replace(/ /g, ''))) {
      case 6243:
        this.set({
          type: ErrorTypes.WRONG_CREDENTIALS,
          icon: 'rectify-error',
          message: 'Det nuvarande lösenordet du angav är felaktigt.'
        });
        break;
      case 6124:
        this.set({
          type: ErrorTypes.PASSWORD_UNCHANGED,
          icon: 'rectify-error',
          severe: false,
          message: 'Lösenordet får inte vara identiskt med ditt nuvarande lösenord.'
        });
        break;
      case 6125:
        this.set({
          type: ErrorTypes.PASSWORD_TOO_SHORT,
          icon: 'rectify-error',
          severe: false,
          message: 'Lösenordet har inte tillräckligt många tecken.'
        });
        break;
      default:
        this.set(err);
    }
  }
  set({
    type,
    message,
    parameter,
    severe
  }) {
    this.type = type || ErrorTypes.GENERAL;
    this.title = 'Lösenordet kunde inte bytas';
    this.message = message || 'Ett oväntat fel inträffade.<br/>Vid frågor, kontakta ombudssupport 020-78 83 55';
    this.severe = severe === undefined ? true : severe;
    this.parameter = parameter;
  }
}
export default PasswordChangeError;